<template>
  <div class="px-5" id="page-event-details">
    <b-modal ref="loginModal" hide-footer no-close-on-backdrop>
      <TicketCartSummaryRow 
        class="mt-4 mb-4" 
        :numTickets="numTickets" 
        :sumTickets="sumTickets" 
        :currency="currency"
      />

      <EventLogin 
        v-if="!isMemAuthenticated"
        :company_id="event.company_id" 
        @addUserToEvent="addUserToEvent" 
        @onSuccessLogin="onSuccessLogin"
        @on_pending_payment="on_pending_payment"
      />

      <MemberLoggedInCard 
        v-if="isMemAuthenticated"
        @joinEvent="joinEvent"
      />

    </b-modal>

    <b-modal :title="$t('PAGES.EVENTS.CHOOSE_TICKETS')" ref="selectTickets" hide-footer no-close-on-backdrop>
      
      <div class="contain-info">
        <h5>{{$t('PAGES.EVENTS.MAXIMUM_TICKETS', { max_tickets: event.max_tickets_per }) }}</h5>
      </div>

      <div class="contain-tickets">
        <template v-for="(item, i) in event.shop_items">
          <TicketSelectRow 
            v-bind:key="i" 
            :vat_option="event.vat_option"
            :event_shop_item="item" 
            @numEventShopItemsChanged="numEventShopItemsChanged" 
            :max_tickets_per="event.max_tickets_per"
            :is_only_ticket="event.shop_items.length === 1"
          />
        </template>
      </div>

      <b-row v-if="event.vat_option">
        <b-col lg="8">
          <p class="vat-option">{{ $t('EVENT.VAT_OPTIONS.' + event.vat_option.toUpperCase()) }}</p>
        </b-col>
        <b-col lg="4"><div class="sum-box">{{ sumTickets }} {{ event.shop_items && event.shop_items.length > 0 ? event.shop_items[0].currency : 'SEK' }}</div></b-col>
      </b-row>

      <b-row class="mt-8" v-if="maxExceeded">
        <b-col>
          <div class="alert alert-custom alert-light-danger fade show mb-5" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">{{$t('PAGES.EVENTS.MAXIMUM_ORDER')}} {{event.max_tickets_per}} {{event.max_tickets_per === 1 ? $t('PAGES.EVENTS.TICKET') : $t('PAGES.EVENTS.TICKETS') }}</div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-8">
        <b-col lg="8"></b-col>
        <b-col lg="4">
          <button
              v-if="isTicketStepOK"
              type="button"
              class="btn btn-primary"
              style="width: 100%;"
              @click="continueFromTicketStep"
            >
              {{$t('COMMON.CONTINUE')}}
            </button>
        </b-col>
      </b-row>
      
    </b-modal>

    <b-modal ref="questions" hide-footer no-close-on-backdrop>
      <AttributeGroupContainer 
        :group_ids="event.group_ids"
        :page_index="answer_page_index"
        @selected="answer_selected"
      />

      <button
        type="button"
        class="btn btn-primary mt-4"
        style="width: 100%;"
        :disabled="disabled_continue_questions_button"
        @click="continue_next_question"
      >
        {{$t('COMMON.CONTINUE')}}
      </button>
    
    </b-modal>

    <b-modal :title="$t('PAGES.EVENTS.TERMS')" ref="termsModal" hide-footer no-close-on-backdrop>
      <b-card style="padding: 8px; max-height: 640px; overflow-y: scroll;" v-html="event.terms">
      </b-card>

      <div class="d-flex align-items-center mb-4 mt-8">
        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" v-model="isAcceptTerms" @click="isAcceptTerms = !isAcceptTerms" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.ACCEPT_TERMS')}}</span>
      </div>
      

      <button
          v-if="isAcceptTerms"
          type="button"
          class="btn btn-primary"
          style="width: 100%;"
          @click="continueFromAcceptTerms"
        >
          {{$t('COMMON.CONTINUE')}}
        </button>
    </b-modal>

    

    <b-modal ref="noAccountModal" hide-footer no-close-on-backdrop>
      <TicketCartSummaryRow 
        class="mt-4 mb-4" 
        :numTickets="numTickets" 
        :sumTickets="sumTickets" 
        :currency="currency"
      />

      <RegisterMemberNoAccount
        @submitForm="proceedFromNoAccountData"
        :open_setting="event.open_setting"
        :company_id="event.company_id"
      />
    </b-modal>

    <b-modal ref="successJoin" hide-footer>
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h4 class="event-name" style="text-align: center;">{{ event.name }}</h4>
          <br />
          <p>
            {{$t('PAGES.EVENTS.EMAIL_TICKET')}}
          </p>
        </div>
      </div>
    </b-modal>

    <b-row class="mt-16" v-if="this.preview_event_id">
      <b-col lg="12">
        <b-card :title="event.name" class="mb-2 shadow-sm" hide-footer>
          <div class="d-flex justify-content-end">
            <a href="#" @click="gotoEvent()">
              {{$t('PAGES.EVENTS.READ_MORE')}}
            </a>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row :class="this.preview_event_id ? 'mt-4' : 'mt-16'">
      <b-col lg="12" v-if="event.type === 'PHY'">
        <google-map
          :center="{ lat: event.google_maps.lat, lng: event.google_maps.lng }"
          :zoom="16"
          style="height: 256px"
          id="map"
          ref="Map"
          class="shadow-sm p-2 mb-5 rounded"
        >
          <google-map-marker
            :position="{ lat: event.google_maps.lat, lng: event.google_maps.lng }"
          ></google-map-marker>
        </google-map>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12">
        <b-card :title="$t('PAGES.EVENTS.DETAILS')" class="mb-2 shadow-sm" hide-footer>
          <div class="row">
            <div class="col-8">
              <div class="title-info">
                <h4>{{$t('PAGES.EVENTS.DATE_TIME')}}</h4>
                <p>{{ from_formatted }}</p>
              </div>
              <div class="title-info" v-if="event.use_deadline">
                <div v-if="deadlinePassed">
                  
                  <h4 style="color: red;">{{$t('PAGES.EVENTS.SIGNUP_DATE_EXPIRED')}}</h4>
                  
                </div>
                <div v-else>
                  <h4>{{$t('PAGES.EVENTS.LAST_SIGNUP_DATETIME')}}</h4>
                  <p>{{ event.deadline_datetime }}</p>
                </div>
                
              </div>
              <div class="title-info">
                <h4>{{$t('PAGES.EVENTS.LOCATION')}}</h4>
                <p v-if="event.location_descr && event.location_descr.length > 4">
                  {{ event.location_descr }}
                </p>
                <p v-if="event.type === 'PHY'">
                  {{ event.address }}, {{ event.zipcode }} {{ event.postaladdress }}
                </p>
                <p v-if="event.type === 'ONL'">{{$t('PAGES.EVENTS.EVENT_ONLINE')}}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="title-info">
                <p
                  v-if="iAmSignedUp"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.REGISTERED')}}
                </p>

                <p
                  v-if="isFull"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.EVENT_FULL')}}
                </p>

                <p
                  v-if="!isFull"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.EVENT_NOT_FULL')}}
                </p>
              </div>
              <div>
                <!--Button for when NOT logged in-->
                <button
                  v-if="!iAmSignedUp && !isFull && !deadlinePassed"
                  id="btn-login-modal"
                  type="button"
                  class="btn btn-primary"
                  @click="signup"
                >
                  {{$t('PAGES.EVENTS.SIGN_UP')}}
                </button>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4" v-if="isExistsLink()">
      <b-col lg="12">
        <b-card :title="$t('PAGES.EVENTS.LINKS')" class="mb-2 shadow-sm" hide-footer>
          <a
            :href="event.facebook_url"
            :title="event.facebook_url"
            class="d-flex mb-3"
            v-if="
              event.facebook_url !== undefined &&
                event.facebook_url != '' &&
                this.event.facebook_url != 'https://'
            "
          >
            <i class="fab fa-facebook-square mr-5" style="font-size:36px"></i>
            <span class="align-self-center">{{$t('PAGES.EVENTS.FACEBOOK_INFO')}}</span>
          </a>
          <a
            :href="event.extern_url"
            :title="event.extern_url"
            class="d-flex"
            v-if="
              event.extern_url !== undefined &&
                event.extern_url != '' &&
                this.event.extern_url != 'https://'
            "
          >
            <!-- <i class="fab fa-internet-explorer pr-2 " style="font-size:36px" :title="event.extern_url"></i> -->
            <i class="fas fa-globe pr-1 mr-3" style="font-size:36px" :title="event.extern_url"></i>
            <span class="align-self-center" >
              {{$t('PAGES.EVENTS.VISIT_WEBPAGE')}}
            </span>
          </a>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4" v-if="!this.preview_event_id">
      <b-col lg="12">
        <b-card :title="event.name" class="mb-2 shadow-sm" hide-footer>
          <div v-html="event.descr_html"></div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4" v-if="this.preview_event_id">
      <b-col lg="12">
        <div class="d-flex justify-content-end">
          <a href="#" class="btn btn-sm mx-3 font-weight-bold" @click="gotoEvent()">
            <!-- <span class="svg-icon svg-icon-md svg-icon-primary"> -->
            <!-- Läs mer -->
            <!-- </span> -->
          </a>
        </div>
      </b-col>
    </b-row>

    <ErrorPopup :error="error_popup_message" :error_extras="error_extras" />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.contain-info {
  padding: 8px;
}

.contain-tickets {
  border: 1px solid #EBEDF3;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

img {
  width: 100%;
  height: auto;
}

.event_style {
  background-color: grey;
}

.sum-box {
  border: 1px solid #dedede;
  text-align: center;
  padding: 8px;
}

.vat-option {
  font-size: 12px;
  color: #6c757d;
  text-align: right;
  margin-top: 10px;
}

</style>

<script>
import messageParent from '@/core/services/messageParent';
import axios from 'axios';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import EventLogin from '@/view/pages/members/public/EventLogin.vue';
import TicketSelectRow from '@/view/pages/members/public/TicketSelectRow.vue';
import TicketCartSummaryRow from '@/view/pages/members/public/TicketCartSummaryRow.vue';
import RegisterMemberNoAccount from '@/view/pages/members/auth/RegisterMemberNoAccount.vue';
import MemberLoggedInCard from '@/view/pages/members/public/MemberLoggedInCard.vue'
import AttributeGroupContainer from '@/view/components/attribute_forms/AttributeGroupContainer.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {
  name: 'PublicEventDetails',
  components: {
    EventLogin,
    RegisterMemberNoAccount,
    TicketSelectRow,
    TicketCartSummaryRow,
    MemberLoggedInCard,
    AttributeGroupContainer,
    ErrorPopup
  },
  mixins: [ toasts ],
  props: {
    preview_event_id: { type: Number, default: 0 },
    member_id: { type: [String, Number], default: -1 }
  },
  computed: {
    disabled_continue_questions_button() {
      console.log('COMPUTING disabled_continue_questions_button')
      if (this.event.group_ids && this.event.group_ids.length > 0) {
        const group_id = this.event.group_ids[this.answer_page_index];
        const group = this.event.groups.find(item => item.group_id === group_id);

        if (group.is_mandatory) {
          console.log('COMPUTING is mandatory', group, this.answer_page_index);
          const found = this.property_group_answers.find(item => item.group_id === group.group_id);

          if (!found) {
            console.log('answer not found');
            return true;
          }

          if (found.selected_attributes.length === 0) {
            console.log('found but no answer');
            return true;
          }
        }
      }

      console.log('button is enabled')
      return false;
    },
    currency() {
      if (this.tickets && this.tickets.length > 0 && this.tickets[0].shop_item) {
        return this.tickets[0].shop_item.currency;
      }
      
      return 'SEK';
    },
    deadlinePassed() {
      return this.event.use_deadline && dayjs(this.event.deadline_datetime) < dayjs();
    },
    ...mapGetters(['isMemAuthenticated'])
  },
  mounted() {
    if (this.$route.query.lang) {
      const new_lang = this.$route.query.lang;

      if (new_lang.length === 2) {
        if (localStorage.getItem("language") !== new_lang) {
          localStorage.setItem("language", new_lang);
          i18nService.locale = new_lang;
          location.reload();
        }

      }

    }
    
    this.loadEvent();
  },
  data() {
    return {
      error_popup_message: null,
      error_extras: null,
      answer_page_index: 0,
      property_group_answers: [], // { group_id: number, properties: { prop_id: number, text: string | undefined } []}
      maxExceeded: false,
      attendeeData: {
        form: null,
        is_member: false
      },
      isAcceptTerms: false,
      isTicketStepOK: false,
      numTickets: 0,
      sumTickets: 0,
      tickets: [],
      testEventShopItem: {
        id: 0,
        shop_item: {
          name: 'Detta är ett test',
          price_sek: 100
        }
      },
      event: {
        google_maps: { lat: 0, lng: 0 },
        use_deadline: false,
        deadline_datetime: null
      },
      from_formatted: '',
      price: '',
      count_members: 0,
      iAmSignedUp: false,
      isFull: false,
      member_has_active_membership: false
    };
  },
  watch: {
    event(newVal, oldVal) {

    }
  },
  methods: {
    on_pending_payment(data) {
      console.log('member has pending payments', data);
    },
    continue_next_question() {
      if (this.answer_page_index +1 === this.event.group_ids.length) {
        this.$refs['questions'].hide();

        // end of questions
        this.proceedToAcceptTermsIfHasTerms();
      }
      else {
        this.answer_page_index++;
      }
    },
    /* 
    group = { 
      group_id: number;
      selected_attributes: { prop_id: number, text: string | null }[] 
    } */
    answer_selected(group_with_answer) {

      console.log('IN EVENT answer_selected')
      
      const found = this.property_group_answers.find(item => item.group_id === group_with_answer.group_id);

      if (!found) {
        this.property_group_answers.push(group_with_answer);
      }
      else {
        found.selected_attributes = group_with_answer.selected_attributes;
      }

      this.property_group_answers = [...this.property_group_answers];

      console.log('answer_selected: this.property_group_answers', this.property_group_answers)
    },
    reset_tickets() {
      this.maxExceeded = false;
      this.isTicketStepOK = false;
      this.numTickets = 0;
      this.sumTickets = 0;
      this.tickets = [];
    },
    continueFromAcceptTerms() {
      this.$refs['termsModal'].hide();

      if (this.attendeeData.is_member) {
        this.signupAccount(this.attendeeData.form);
      }
      else {
        this.signupNoAccount(this.attendeeData.form, []);
      }
    },
    continueFromTicketStep() {
      this.$refs['selectTickets'].hide();

      if (this.event.open_setting === 'PUBLIC' && !this.isMemAuthenticated) {
        this.$refs['noAccountModal'].show();
      } 
      else {
        this.$refs['loginModal'].show();
      }
    },
    numEventShopItemsChanged(event_shop_item, num) {
      const t = this.tickets.find(item => item.id === event_shop_item.id);

      if (num === 0) {
        this.tickets = this.tickets.filter(item => item.id !== event_shop_item.id);
      }

      if (!t) {
        event_shop_item.quantity = num;
        
        this.tickets.push(event_shop_item);

        this.calculateTicketSum();
        return;
      }

      t.quantity = num;

      this.calculateTicketSum();
    },
    validateTicketStep() {
      this.numTickets = 0;

      for (const t of this.tickets) {
        this.numTickets += t.quantity;
      }
      
      if (this.numTickets > this.event.max_tickets_per) {
        this.maxExceeded = true;
        return false;
      }

      this.maxExceeded = false;


      return this.numTickets > 0;
    },
    calculateTicketSum() {
      let sum = 0;
      
      if (this.event.vat_option === 'INC_VAT') {
        for (const t of this.tickets) {
          sum += t.shop_item.amount * t.quantity;
        }

        this.sumTickets = sum;
      }
      else {
        for (const t of this.tickets) {
          sum += (t.shop_item.amount * t.quantity) - (t.shop_item.amount_vat * t.quantity);
        }

        this.sumTickets = sum;
      }

      this.isTicketStepOK = this.validateTicketStep();

      return this.sumTickets;
    },
    openTicketSelector() {
      this.reset_tickets();

      this.$refs['selectTickets'].show();
    },
    signup() {
      this.openTicketSelector();
    },
    showModal() {
      this.$refs['loginModal'].show();
    },
    hideModal() {
      this.$refs['loginModal'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['loginModal'].toggle('#toggle-btn');
    },
    async loadData() {
      this.loadEvent();
    },

    loadEvent() {
      this.event_id = this.$route.params.id;

      if (this.preview_event_id) {
        this.event_id = this.preview_event_id;
      }

      axios
        .get(`/events/${this.event_id}`)
        .then(res => {
          this.event = res.data;
          setKeyValue('url_after_event_signup', this.event.url_after_event_signup);

          if (this.event.from_datetime === '' || this.event.from_datetime === null) {
            this.event.from_datetime = '2020-12-01T20:00:00';
          }

          if (this.event.to_datetime === '' || this.event.to_datetime === null) {
            this.event.to_datetime = '2099-12-31T20:00:00';
          }

          var mom =
            dayjs(this.event.from_datetime).format('YYYY-MM-DD HH:mm') +
            ' till ' +
            dayjs(this.event.to_datetime).format('YYYY-MM-DD HH:mm');

          if (this.event.cost === 0) {
            this.price = this.$t('PAGES.EVENTS.FREE');
          } else {
            this.price = this.event.cost; // todo, add currency
          }

          this.from_formatted = mom;

          this.isFull = !(this.event.num_signups < this.event.num_seats);

          setTimeout(function() {
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_GET'));
          this.$router.push('/404');
        });
    },

    continueNoAccount() {
      this.$refs['loginModal'].hide();
      this.$refs['noAccountModal'].show();
    },

    proceedFromNoAccountData(form, company_ids) {
      this.attendeeData.form = form;
      this.attendeeData.company_ids = company_ids;
      this.attendeeData.is_member = false;

      this.proceed_to_question_if_has_question();
    },

    proceedFromAccountData(form) {
      this.attendeeData.form = form;
      this.attendeeData.is_member = true;

      this.proceed_to_question_if_has_question();
    },

    proceed_to_question_if_has_question() {
      if (this.event.group_ids && Array.isArray(this.event.group_ids) && this.event.group_ids.length > 0) {
        this.answer_page_index = 0;
        this.$refs['questions'].show();
      }
      else {
        this.proceedToAcceptTermsIfHasTerms();
      }
    },

    proceedToAcceptTermsIfHasTerms() {
      if (this.event.has_terms) {
        this.$refs['termsModal'].show();
      }
      else {
        this.continueFromAcceptTerms();
      }
    },

    signupNoAccount(form, company_ids) {

      var data = form;
      data.company_ids = company_ids;
      data.event_id = this.event.event_id;
      data.signedup = 1;
      data.ts_signup = dayjs().format('YYYY-MM-DD');
      data.tickets = this.tickets;
      data.send_ticket_email = true;
      data.property_group_answers = this.property_group_answers;

      if (data.tickets.length > this.event.max_tickets_per) {
        this.error_popup_message = 'ERR_EVENTSIGNUP_MAX_TICKETS';
        this.$nextTick(()=>{ this.error_popup_message = null; });

        return;
      }

      axios
        .post('/events/joinnoaccount', data)
        .then(res => {
          if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.EVENT_FULL'));
            return;
          }
          
          if (res.status == 201) {
            this.event.num_signups++;

            this.isFull = !(this.event.num_signups < this.event.num_seats);
            this.iAmSignedUp = true;

            if (res.data.order && res.data.order.expected_action !== 'NO_PAYMENT') {
              this.proceedToOrder(res.data.order);
            }
            else {
              this.$refs['noAccountModal'].hide();
              this.$refs['successJoin'].show();

              const url = getKeyValue('url_after_event_signup');

              if (url) {
                setTimeout(()=>{
                  location.href = url;
                }, 2000);
              }
            }
          }
          else if (res.status === 409) {
            this.error_popup_message = 'ERR_EVENTSIGNUP_ANY_MEMBER';
            this.$nextTick(()=>{ this.error_popup_message = null; });
          }
          else {
            this.error_popup_message = 'ERR_EVENTSIGNUP_UNDEFINED_ERROR';
            this.error_extras = `Event: ${this.event.event_id}, NoMember, Response: ${res.status}, Reason: ${res.data.reason}`
            this.$nextTick(()=>{ this.error_popup_message = null; });

            return;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.ERROR_SIGN_UPP'));
        });

    },
    
    signupAccount(form) {
      var data = form;
      data.event_id = this.event.event_id;

      data.tickets = this.tickets;
      data.send_ticket_email = true;
      data.property_group_answers = this.property_group_answers;

      if (data.tickets.length > this.event.max_tickets_per) {

        this.error_popup_message = 'ERR_EVENTSIGNUP_MAX_TICKETS';
        this.$nextTick(()=>{ this.error_popup_message = null; });

        return;
      }

      axios
        .post('/events/joinmember', data)
        .then(res => {
          if (res.status === 201) {
            this.$refs['loginModal'].hide();
            this.$refs['successJoin'].show();

            this.event.num_signups++;

            this.iAmSignedUp = true;
            this.isFull = !(this.event.num_signups < this.event.num_seats);

            if (res.data.order && res.data.order.expected_action !== 'NO_PAYMENT') {
              this.proceedToOrder(res.data.order);
            }
          } 
          else if (res.status === 409) {
            if (res.data.reason === 'COMPANY_MEMBER_PAID') {
              this.error_popup_message = 'ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID';
              this.$nextTick(()=>{ this.error_popup_message = null; });
            }
            else if (res.data.reason === 'MAX_TICKETS') {
              this.error_popup_message = 'ERR_EVENTSIGNUP_MAX_TICKETS';
              this.$nextTick(()=>{ this.error_popup_message = null; });
            }
            else if (res.data.reason === 'ANY_MEMBER_PAID') {
              this.error_popup_message = 'ERR_EVENTSIGNUP_ANY_MEMBER_PAID';
              this.$nextTick(()=>{ this.error_popup_message = null; });
            }
            else {
              this.error_popup_message = 'ERR_EVENTSIGNUP_UNDEFINED_ERROR';
              this.error_extras = `Event: ${this.event.event_id}, MemberId: ${this.member_id}, Response: 409, Reason: ${res.data.reason}`
              this.$nextTick(()=>{ this.error_popup_message = null; });
            }
          }
          else {
            this.error_popup_message = 'ERR_EVENTSIGNUP_UNDEFINED_ERROR';
            this.error_extras = `Event: ${this.event.event_id}, MemberId: ${this.member_id}, Response: ${res.status}, Reason: ${res.data.reason}`
            this.$nextTick(()=>{ this.error_popup_message = null; });
          }
        })
        .catch(err => {
          console.error(err);
          
          this.error_popup_message = 'ERR_EVENTSIGNUP_UNDEFINED_ERROR';
          this.error_extras = `Event: ${this.event.event_id}, MemberId: ${this.member_id}, Exception: ${err}`
          this.$nextTick(()=>{ this.error_popup_message = null; this.error_extras = null; });
        });
    },
    proceedToOrder(order) {

      if (order.expected_action === 'SELECT_PAYMENT') {
        this.$router.push(`/select-payment/${order.token}/${order.shop_order_id}`);
      }
      else if (order.expected_action === 'STRIPE_PAYMENT') {
        this.$router.push(`/stripe-payment/${order.member_id}?client_secret=${order.stripe_client_secret}&type=event`);
      }
      else if (order.expected_action === 'DINTERO_PAYMENT') {
        this.$router.push(`/event-payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
      }
      else {
        this.error_popup_message = 'ERR_EVENTSIGNUP_UNDEFINED_ERROR';
        this.error_extras = `Event: ${this.event.event_id}, MemberId: ${this.member_id}, Payment invalid action: ${order.expected_action}, ${order.shop_order_id}`
        this.$nextTick(()=>{ this.error_popup_message = null; this.error_extras = null; });
      }
    },
    
    onSuccessLogin(member) {
      this.member_has_active_membership = false;
      axios
        .get(`/member/me`)
        .then(res => {
          res.data.member.member_companies.forEach(item => { 
            if (item.active === 1) {
              this.member_has_active_membership = true;
            }
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_OCCURED'));
        });
    },

    async joinEvent(member) {

      try {

        if (this.event.members_only) {
          const me = await axios.get(`/member/me`);

          if (me.status !== 200) {
            this.error_popup_message = 'ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP';    
            this.$nextTick(()=>{ this.error_popup_message = null; this.error_extras = null; });

            return;
          }

          this.member_has_active_membership = true;

          me.data.member.member_companies.filter(item => item.active); 

          if (!this.member_has_active_membership) {
            this.error_popup_message = 'ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP';    
            this.$nextTick(()=>{ this.error_popup_message = null; this.error_extras = null; });

            return;
          }
        }
        
        this.addUserToEvent(member.member_id);
      }
      catch (err) {
        console.error('joinEvent', err);
      }
      
    },

    addUserToEvent(member_id) {
      this.proceedFromAccountData({ member_id: member_id, event_id: this.event.event_id, tickets: this.tickets })

    },
    isExistsLink() {
      return (
        (this.event.extern_url !== undefined &&
          this.event.extern_url != '' &&
          this.event.extern_url != 'https://') ||
        (this.event.facebook_url !== undefined &&
          this.event.facebook_url != '' &&
          this.event.facebook_url != 'https://')
      );
    },

    gotoEvent() {
      this.$router.push(`/event/${this.event.event_id}`);
    },

  }
};
</script>
