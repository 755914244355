<template>
  <div class="no-padding">

    <AttributeGroupQuestion
      :index="page_index"
      :property_group="local_groups[page_index]"
      @selected="selected"
    />

  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import AttributeGroupQuestion from '@/view/components/attribute_forms/AttributeGroupQuestion.vue';

export default {

  name: 'AttributeGroupContainer',

  props: ['group_ids','page_index'],
  emits: ['selected'],
  mixins: [ toasts ],

  components: {
    AttributeGroupQuestion
  },

  watch: {
    group_ids: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_group_ids = [...this.group_ids];

        this.load_groups();
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  mounted() {
    if (this.group_ids) {
      this.local_group_ids = [...this.group_ids];

      this.load_groups();
    }
  },

  methods: {
    /*
    group = {
      group_id: number;
      selected_attributes: { prop_id: number, text: string | null }[]
    } */
    selected(group) {
      this.$emit('selected', group);
    },

    open_modal() {
      this.$refs['select-attributes-modal'].show();
    },

    async load_groups() {
      try {
        const res = await axios.post(`/property_group/list`, { group_ids: this.local_group_ids });

        if (res.status === 200) {
          const tmp_groups = res.data;

          this.local_groups = [];

          // make sure the groups are ordered the same way as the group_ids
          for (const group_id of this.local_group_ids) {
            const group = tmp_groups.find((item) => item.group_id === group_id);
            this.local_groups.push(group);
          }
        }
      }
      catch (err) {
        console.error('load_groups error', err);
      }

    }
  },

  data() {
    return {
      local_group_ids: [],
      local_groups: []
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
